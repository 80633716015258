import * as Sentry from '@sentry/nextjs';
import Error from 'next/error';

const sentryDsn = process.env.NEXT_PUBLIC_SENTRY_DSN;

const CustomErrorComponent = (props) => {
  return <Error statusCode={props.statusCode} />;
};

CustomErrorComponent.getInitialProps = async (contextData) => {
  if (sentryDsn) {
    await Sentry.captureUnderscoreErrorException(contextData);
  }

  return Error.getInitialProps(contextData);
};

export default CustomErrorComponent;
